#pending_accounts {
  .info_box {
    > ul {
      > li {
        h3 {
          font-size: 24px;
          font-weight: 600;
          line-height: 1.42;
          color: $primary-green;
          margin-bottom: 24px;
        }

        &.notes {
          margin-bottom: 40px;
        }

        .primary {
          margin-bottom: 32px;
        }

        .secondary {
          padding: 32px 0;
          margin-bottom: 32px;
        }

        p {
          font-size: 16px;
          font-weight: 200;
          line-height: 1.56;
          color: $primary-purple;
        }

        a {
          color: $primary-purple;
          text-decoration: underline;
        }

        ul {
          @include rtl-sass-prop(padding-left, padding-right, 30px);
          li {
            list-style: disc;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 200;
            line-height: 1.56;
            color: $primary-purple;
          }
        }
      }
    }
  }

  .table_content {
    .table_box {
      margin: 40px 0px 33px;
    }
  }
}

@media (max-width: 550px) {
  #pending_accounts {
    .info_box {
      padding: 24px;

      > ul > li {
        h3 {
          font-size: 20px;
        }
      }
    }
  }
}
